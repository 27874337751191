import React, {Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {pluralise} from 'admin/lib/format'
import useOrganisation from 'admin/hooks/useOrganisation'
import useStateObject from 'admin/hooks/useStateObject'
import GroupSelect from 'admin/components/ui/GroupSelect'
import EditField from 'admin/components/ui/EditField'
import ShowField from 'admin/components/ui/ShowField'

export default ({onSave, onCancel, accounts = []}) => {
  const {organisationID} = useParams()
  const {organisation} = useOrganisation(organisationID)
  const [form, setForm, resetForm] = useStateObject()
  const [selectedGroupIds, setSelectedGroupIds] = useState([])
  const [deselectedGroupIds, setDeselectedGroupIds] = useState([])

  const partialSelectedGroupIds = accounts.reduce((groupIds, account) => {
    if (account.groups) {
      account.groups.forEach(group => {
        let groupId = String(group.id)
        if (
          !groupIds.includes(groupId) &&
          !selectedGroupIds.includes(groupId) &&
          !deselectedGroupIds.includes(groupId)
        ) {
          groupIds.push(groupId)
        }
      })
    }
    return groupIds
  }, [])

  const handleRemoveGroup = ({id}) => {
    setSelectedGroupIds(selectedGroupIds.filter(groupId => String(groupId) !== String(id)))
    setDeselectedGroupIds([...deselectedGroupIds, id])
  }

  const handleAddGroup = ({id}) => {
    setSelectedGroupIds([...selectedGroupIds, id])
    setDeselectedGroupIds(deselectedGroupIds.filter(groupId => String(groupId) !== String(id)))
  }

  const handleSave = () => {
    onSave({
      ...form,
      groups: [...selectedGroupIds.map(id => ({id})), ...deselectedGroupIds.map(id => ({id, _destroy: true}))]
    })
  }

  const handleCancel = () => {
    resetForm()
    setSelectedGroupIds([])
    setDeselectedGroupIds([])
    onCancel()
  }

  return (
    <Fragment>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered'>
            <div className='column'>
              <h3 className='title is-marginless'>Edit {pluralise(`account`, accounts.length, true)}</h3>
            </div>
            <div className='column buttons is-right'>
              <button onClick={handleCancel} className='button is-text is-decorationless'>
                Cancel
              </button>
              <button onClick={handleSave} className='button is-primary'>
                Save
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <ShowField label='Groups'>
            <GroupSelect
              selectedIds={selectedGroupIds}
              partialSelectedIds={partialSelectedGroupIds}
              onSelect={handleAddGroup}
              onUnselect={handleRemoveGroup}
              buttonText='Add to group'
              type='custom'
            />
          </ShowField>
          {organisation.smsEnabled ? (
            <ShowField label='Notifications'>
              <EditField
                name='warn_when_overdue'
                checked={!!form.warn_when_overdue}
                label='Send SMS warnings when overdue'
                type='checkbox'
                onChange={setForm}
              />
            </ShowField>
          ) : null}
        </div>
      </section>
    </Fragment>
  )
}
