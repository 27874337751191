import {gql} from 'admin/lib/apollo'

export const GET_PAYMENT_RULES = gql`
  query GetPaymentRules(
    $organisationId: ID!
    $q: String
    $page: Int
    $limit: Int
    $sortBy: String
    $sortDirection: String
  ) {
    paymentRules(
      organisationId: $organisationId
      q: $q
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      collection {
        id
        description
        account {
          id
          displayName @client
          name
          contactFirstName
          contactLastName
        }
        conditions {
          id
          fieldName
          matchType
          text
        }
      }
      metadata {
        totalCount
      }
    }
  }
`
