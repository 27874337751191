import React, {Fragment} from 'react'
import Dropdown from 'admin/components/ui/Dropdown'
import {titlecase} from 'admin/lib/format'

export default ({id, value, name, options, errors, onChange}) => (
  <Fragment>
    <Dropdown id={id} className={`field${errors ? ' mb-0' : ''}`}>
      <a className={`button${errors ? ' is-danger is-outlined' : ''}`}>
        <span>{options.find(option => option.value === value)?.label || `Select ${titlecase(name)}`}</span>
        <span className='icon'>
          <i className='fa fa-caret-down'></i>
        </span>
      </a>
      {options.map((option, i) => {
        if (option.value === value) return null
        return (
          <a key={i} onClick={() => onChange({[name]: option.value})}>
            {option.label}
          </a>
        )
      })}
    </Dropdown>
    {errors && <span className={`help ${errors ? 'is-danger' : ''}`}>{errors.join(', ')}</span>}
  </Fragment>
)
