import React, {Fragment, useState} from 'react'
import {useParams, useLocation, useHistory, useRouteMatch, Route, Link} from 'react-router-dom'
import {toParams, pluralise} from 'admin/lib/format'
import useAccounts from 'admin/hooks/useAccounts'
import useOrganisation from 'admin/hooks/useOrganisation'
import useEffect from 'admin/hooks/useEffect'
import useDocument from 'admin/hooks/useDocument'
import useBoolState from 'admin/hooks/useBoolState'
import selectableCollection from 'admin/components/hoc/selectableCollection'
import Pagination from 'admin/components/ui/Pagination'
import Dropdown from 'admin/components/ui/Dropdown'
import Confirmation from 'admin/components/Confirmation'
import Modal from 'admin/components/ui/Modal'
import InputField from 'admin/components/ui/InputField'
import NewTextMessage from 'admin/components/NewTextMessage'
import NewAccount from 'admin/components/NewAccount'
import Table from './Table'
import Filters from './Filters'
import EditAccounts from './Edit'

export default () => {
  const {organisationID, state} = useParams()
  const {pathname} = useLocation()
  const match = useRouteMatch()
  const history = useHistory()
  const {organisation} = useOrganisation(organisationID)
  const {accounts, totalCount, filters, setFilters, destroy, activate, archive, merge, update} = useAccounts(
    organisationID,
    {
      state
    }
  )

  const [selected, setSelected] = useState([])
  const [notes, setNotes] = useState(null)
  const [showDeleteModal, setShowDeleteModal, toggleShowDeleteModal] = useBoolState()
  const [showEditModal, setShowEditModal, toggleShowEditModal] = useBoolState()
  const [showTextMessageModal, setShowTextMessageModal, toggleShowTextMessageModal] = useBoolState()

  const title = `Manage ${state} accounts`

  useDocument({title})

  useEffect(() => setSelected([]), [accounts])

  const SelectableTable = selectableCollection(Table, {
    onSelect: setSelected,
    selectedIdentifiers: selected
  })

  const handleRowClick = accountID => {
    history.push(`/orgs/${organisationID}/accounts/${accountID}`)
  }

  const handleUpdate = attributes => {
    update({accountIds: selected, attributes})
    toggleShowEditModal()
  }

  const BulkAction = ({action, children}) => {
    const handleClick = e => {
      e.preventDefault()
      action({accountIds: selected})
    }
    return (
      <a onClick={handleClick} className='dropdown-item'>
        {children}
      </a>
    )
  }

  return (
    <Fragment>
      <Route path={`${match.path}/new`} component={NewAccount} />

      <Confirmation
        isOpen={showDeleteModal}
        confirm={`Are you sure you want to delete ${pluralise(`account`, selected.length, true)}?`}
        buttonClass='is-danger'
        buttonText='Delete'
        onClose={toggleShowDeleteModal}
        onConfirm={() => destroy({accountIds: selected, notes})}
        onCancel={() => setNotes(null)}>
        <InputField
          value={notes || ''}
          type='textarea'
          name='notes'
          label={`Reason for deleting? (optional)`}
          onChange={({notes}) => setNotes(notes)}
          rows={2}
        />
      </Confirmation>

      <Modal onClose={toggleShowEditModal} isOpen={showEditModal}>
        <EditAccounts
          onSave={handleUpdate}
          onCancel={toggleShowEditModal}
          accounts={accounts.filter(a => selected.includes(String(a.id)))}
        />
      </Modal>

      {organisation.smsEnabled ? (
        <Modal onClose={toggleShowTextMessageModal} isOpen={showTextMessageModal}>
          <NewTextMessage accountIds={selected} onSend={toggleShowTextMessageModal} sender={organisation.name} />
        </Modal>
      ) : null}

      <section id='admin_accounts' className='section'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title'>{title}</h3>
              <h4 className='subtitle'>
                Showing {accounts.length} of {pluralise(`${state} account`, totalCount, true)}
              </h4>
            </div>
            {state === 'active' ? (
              <div className='column buttons is-right'>
                <Link to={`${pathname}/new`} replace={true} className='button is-light'>
                  New account
                </Link>
              </div>
            ) : null}
          </div>
          <Filters {...filters} organisationID={organisationID} onSubmit={setFilters} />
          {accounts.length != 0 ? (
            <Fragment>
              <div className='level'>
                {selected.length ? (
                  <div className='level-left has-background-info box mb-0 is-shadowless'>
                    <div className='level-item has-text-white'>
                      {selected.length === accounts.length ? 'All ' : ''}
                      {pluralise('account', selected.length, true)} on this page {pluralise('is', selected.length)}{' '}
                      selected
                    </div>
                    <Dropdown id='accounts-export-menu' className='level-item'>
                      <button className='button is-outlined is-small'>
                        <span>Export</span>
                        <span className='icon is-small'>
                          <i className='fa fa-caret-down' aria-hidden='true'></i>
                        </span>
                      </button>
                      <a
                        href={`/orgs/${organisationID}/downloads/accounts.csv?${toParams({
                          account_ids: selected
                        })}`}
                        className='dropdown-item'>
                        Download CSV
                      </a>
                      <a
                        href={`/orgs/${organisationID}/downloads/accounts.kml?${toParams({
                          account_ids: selected
                        })}`}
                        className='dropdown-item'>
                        Download KML
                      </a>
                    </Dropdown>
                    <Dropdown id='accounts-actions-menu' className='level-item'>
                      <button className='button is-outlined is-small'>
                        <span>Action</span>
                        <span className='icon is-small'>
                          <i className='fa fa-caret-down' aria-hidden='true'></i>
                        </span>
                      </button>
                      {organisation.smsEnabled && state === 'active' ? (
                        <a onClick={toggleShowTextMessageModal} className='dropdown-item'>
                          Text message
                        </a>
                      ) : null}
                      {state === 'draft' ? (
                        <a onClick={toggleShowDeleteModal} className='dropdown-item'>
                          Delete
                        </a>
                      ) : null}
                      {state === 'draft' ? (
                        <BulkAction action={activate}>Activate</BulkAction>
                      ) : state === 'active' ? (
                        <BulkAction action={archive}>Archive</BulkAction>
                      ) : (
                        <BulkAction action={activate}>Unarchive</BulkAction>
                      )}
                      {selected.length >= 2 && selected.length < 3 ? (
                        <BulkAction action={merge}>Merge</BulkAction>
                      ) : null}
                    </Dropdown>
                    <a onClick={toggleShowEditModal} className='level-item button is-outlined is-small'>
                      Edit
                    </a>
                  </div>
                ) : (
                  <div className='level-left'></div>
                )}

                <div className='level-right'>
                  <Pagination
                    page={filters.page}
                    limit={filters.limit}
                    total={totalCount}
                    onChange={setFilters}
                    className='level-item'
                  />
                </div>
              </div>
              <SelectableTable
                isDraft={state === 'draft'}
                data={accounts}
                onClick={handleRowClick}
                onSort={setFilters}
                sortBy={filters.sortBy}
                sortDirection={filters.sortDirection}
              />
              {accounts.length >= 10 && (
                <div className='level'>
                  <div className='level-left'></div>
                  <div className='level-right'>
                    <Pagination
                      page={filters.page}
                      limit={filters.limit}
                      total={totalCount}
                      onChange={setFilters}
                      className='level-item'
                    />
                  </div>
                </div>
              )}
            </Fragment>
          ) : (
            <p>No accounts</p>
          )}
        </div>
      </section>
    </Fragment>
  )
}
