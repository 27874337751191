import React from 'react'
import OptionsSelect from 'admin/components/shared/OptionsSelect'
import InputField from 'admin/components/ui/InputField'

export default ({index, fieldName, matchType, text, errors = {}, isAlone, onChange, onRemove}) => (
  <div className='field is-grouped'>
    <div className='control'>
      <OptionsSelect
        id={`select-paymentRule-${index}-fieldName`}
        value={fieldName}
        name='fieldName'
        onChange={change => onChange(index, change)}
        errors={errors.fieldName}
        options={[
          {value: 'any_field', label: 'Any field'},
          {value: 'details', label: 'Details'},
          {value: 'particulars', label: 'Particulars'},
          {value: 'code', label: 'Code'},
          {value: 'reference', label: 'Reference'},
          {value: 'amount', label: 'Amount'}
        ]}
      />
    </div>
    <div className='control'>
      <OptionsSelect
        id={`select-paymentRule-${index}-matchType`}
        value={matchType}
        name='matchType'
        onChange={change => onChange(index, change)}
        errors={errors.matchType}
        options={[
          {value: 'contains', label: 'contains'},
          {value: 'equals', label: 'equals'},
          {value: 'starts_with', label: 'starts with'},
          {value: 'ends_with', label: 'ends with'}
        ]}
      />
    </div>
    <div className='control is-expanded'>
      <InputField
        placeholder='Text'
        showLabel={false}
        type='text'
        value={text || ''}
        name='text'
        errors={errors.text}
        onChange={change => onChange(index, change)}
      />
    </div>
    {isAlone ? null : (
      <div className='control'>
        <button className='button is-white' onClick={() => onRemove(index)}>
          <span className='icon is-small'>
            <i className='fa fa-times' aria-hidden='true'></i>
          </span>
        </button>
      </div>
    )}
  </div>
)
