import {gql} from 'admin/lib/apollo'

export const BULK_ACCOUNTS = gql`
  mutation BulkAccounts(
    $organisationId: ID!
    $task: String!
    $accountIds: [String!]!
    $notes: String
    $attributes: JSON
  ) {
    accounts(
      organisationId: $organisationId
      task: $task
      accountIds: $accountIds
      notes: $notes
      attributes: $attributes
    ) {
      collection {
        id
        balance
      }
    }
  }
`

export const GET_ACCOUNTS = gql`
  query GetAccounts(
    $organisationId: ID!
    $q: String
    $state: String
    $bounds: String
    $page: Int
    $limit: Int
    $sortBy: String
    $sortDirection: String
    $includeGroupIds: [String!]
    $excludeGroupIds: [String!]
  ) {
    accounts(
      organisationId: $organisationId
      q: $q
      state: $state
      bounds: $bounds
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDirection: $sortDirection
      includeGroupIds: $includeGroupIds
      excludeGroupIds: $excludeGroupIds
    ) {
      collection {
        id
        name
        displayName @client
        physicalAddress
        physicalCoords
        postalAddress
        contactFirstName
        contactLastName
        contactEmail
        contactPhone
        customerNumber
        balance
        createdAt
        updatedAt
        groups {
          id
          colour
          name
        }
        state
        isSuspended
        xeroLink
        warnWhenOverdue
        lastTransactionDate
        overdueSince
      }
      metadata {
        totalCount
      }
    }
  }
`
