import React from 'react'
import {isEmpty} from 'lodash'
import {useParams, useHistory, useLocation} from 'react-router-dom'
import useEffect from 'admin/hooks/useEffect'
import usePaymentRule from 'admin/hooks/usePaymentRule'
import useDocument from 'admin/hooks/useDocument'
import useStateObject from 'admin/hooks/useStateObject'
import InputField from 'admin/components/ui/InputField'
import Modal from 'admin/components/ui/Modal'
import AccountField from 'admin/components/shared/AccountField'
import PaymentRuleCondition from 'admin/components/shared/PaymentRuleCondition'
import useTransaction from 'admin/hooks/useTransaktion'

const DEFAULT_CONDITION = {
  fieldName: 'any_field',
  matchType: 'contains'
}

function useQuery() {
  const {search} = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default () => {
  const history = useHistory()
  const {organisationID} = useParams()
  const query = useQuery()
  const paymentId = query.get('paymentId')
  const {paymentRule, errors, create} = usePaymentRule(organisationID)
  const {transaction: payment} = useTransaction(organisationID, undefined, paymentId)
  const [form, setForm] = useStateObject(isEmpty(paymentRule) ? {conditions: [DEFAULT_CONDITION]} : paymentRule)

  useDocument({title: 'Add payment rule'})

  useEffect(() => setForm(paymentRule), [paymentRule], false)
  useEffect(
    () => {
      let conditions = []
      if (!isEmpty(payment.importDetails))
        conditions.push({fieldName: 'details', matchType: 'equals', text: payment.importDetails})
      if (!isEmpty(payment.importCode))
        conditions.push({fieldName: 'code', matchType: 'equals', text: payment.importCode})
      if (!isEmpty(payment.importParticulars))
        conditions.push({fieldName: 'particulars', matchType: 'equals', text: payment.importParticulars})
      if (!isEmpty(payment.importReference))
        conditions.push({fieldName: 'reference', matchType: 'equals', text: payment.importReference})
      setForm({conditions})
    },
    [payment],
    false
  )

  const handleCreate = e => {
    e.preventDefault()
    create(form, history.goBack)
  }

  const handleConditionChange = (index, change) => {
    let conditions = [...form.conditions]
    conditions[index] = {...conditions[index], ...change}
    setForm({conditions})
  }

  const handleAddCondition = () => {
    let conditions = [...form.conditions]
    conditions.push(DEFAULT_CONDITION)
    setForm({conditions})
  }

  const handleRemoveCondition = index => {
    let conditions = [...form.conditions]
    conditions.splice(index, 1)
    if (isEmpty(conditions)) conditions = [DEFAULT_CONDITION]
    setForm({conditions})
  }

  return (
    <Modal onClose={history.goBack} isOpen={true}>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns  is-vcentered is-mobile'>
            <div className='is-5 column'>
              <h3 className='title is-marginless'>Add payment rule</h3>
            </div>
            <div className='is-7 column buttons is-right'>
              <button className='button is-success' onClick={handleCreate}>
                Add
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <p className='title is-5 mt-0'>When...</p>

          {form.conditions.map((condition, i) => (
            <PaymentRuleCondition
              key={i}
              index={i}
              isAlone={form.conditions.length === 1}
              {...condition}
              errors={errors.conditions && errors.conditions[i]}
              onChange={handleConditionChange}
              onRemove={handleRemoveCondition}
            />
          ))}

          <div className='buttons is-right'>
            <button className='button is-outlined is-dark' onClick={handleAddCondition}>
              Add another condition
            </button>
          </div>

          <p className='title is-5 mt-0'>match the payment to...</p>

          <AccountField
            placeholder='Account'
            showLabel={false}
            value={form.account?.displayName || ''}
            organisationID={organisationID}
            onSelect={account => setForm({account, accountId: account?.id})}
            errors={errors.account}
          />

          <p className='title is-5'>and set the description to...</p>

          <InputField
            placeholder='Description'
            showLabel={false}
            name='description'
            value={form.description || ''}
            errors={errors.description}
            onChange={setForm}
          />
        </div>
      </section>
    </Modal>
  )
}
