import {gql} from 'admin/lib/apollo'

export const GET_TRANSACTION = gql`
  query GetTransaction($organisationId: ID!, $transactionId: ID!) {
    transaction(organisationId: $organisationId, id: $transactionId) {
      id
      description
      amount
      date
      importCode
      importDetails
      importParticulars
      importReference
    }
  }
`

export const CREATE_TRANSACTION = gql`
  mutation CreateOrUpdateTransaction(
    $organisationId: ID!
    $description: String
    $amount: Float
    $date: String
    $accountId: ID
    $reconciled: Boolean
  ) {
    transaction(
      organisationId: $organisationId
      description: $description
      amount: $amount
      date: $date
      accountId: $accountId
      reconciled: $reconciled
    ) {
      id
      description
      amount
      date
    }
  }
`

export const UPDATE_TRANSACTION = gql`
  mutation CreateOrUpdateTransaction(
    $organisationId: ID!
    $transactionId: ID!
    $description: String
    $amount: Float
    $date: String
    $accountId: ID
    $reconciled: Boolean
    $notes: String
  ) {
    transaction(
      organisationId: $organisationId
      id: $transactionId
      description: $description
      amount: $amount
      date: $date
      accountId: $accountId
      reconciled: $reconciled
      notes: $notes
    ) {
      id
      description
      amount
      date
    }
  }
`
