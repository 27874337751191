import React, {Fragment, useState} from 'react'
import {useParams, useLocation, useRouteMatch, Route, Link, useHistory} from 'react-router-dom'
import {pluralise, titlecase} from 'admin/lib/format'
import useEffect from 'admin/hooks/useEffect'
import useDocument from 'admin/hooks/useDocument'
import useBoolState from 'admin/hooks/useBoolState'
import selectableCollection from 'admin/components/hoc/selectableCollection'
import Pagination from 'admin/components/ui/Pagination'
import Dropdown from 'admin/components/ui/Dropdown'
import Confirmation from 'admin/components/Confirmation'
import InputField from 'admin/components/ui/InputField'
import usePaymentRules from 'admin/hooks/usePaymentRules'
import Page from 'admin/components/Page'
import NewPaymentRule from 'admin/components/NewPaymentRule'
import PaymentRule from 'admin/components/PaymentRule'
import sortable from 'admin/components/hoc/sortable'

const Table = ({onClick, onSort, sortBy, sortDirection, data, SelectAll, SelectOne}) => {
  const {organisationID} = useParams()
  const SortableTH = sortable('th', {onSort, sortBy, sortDirection, camelcase: true})

  return (
    <table className='table is-striped is-hoverable is-fullwidth is-linked'>
      <colgroup>
        <col span='1' style={{width: '4.5%'}} />
        <col span='1' style={{width: '25.5%'}} />
        <col span='1' style={{width: '70%'}} />
      </colgroup>

      <thead>
        <tr>
          <th>
            <SelectAll />
          </th>
          {/* <SortableTH name='Account name' column='name' /> */}
          <th>Name</th>
          <th>Conditions</th>
        </tr>
      </thead>

      <tbody>
        {data.map((rule, i) => (
          <tr
            key={i}
            onClick={e => {
              if (e.target.tagName.toLowerCase() !== 'a' && e.target.type !== 'checkbox') {
                e.preventDefault()
                onClick(rule.id)
              }
            }}>
            <td>
              <SelectOne identifier={rule.id} />
            </td>
            <td>
              <Link to={`/orgs/${organisationID}/accounts/${rule.account.id}`}>{rule.account.displayName}</Link>
            </td>
            <td>
              {rule.conditions.map((condition, i) => (
                <Fragment key={i}>
                  {titlecase(condition.fieldName)} {condition.matchType}{' '}
                  <span className='has-text-weight-bold'>{condition.text}</span>
                  {i < rule.conditions.length - 1 ? ' | ' : null}
                </Fragment>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default () => {
  const {organisationID} = useParams()
  const {pathname} = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const {paymentRules: rules, totalCount, filters, setFilters, destroy} = usePaymentRules(organisationID)

  const [selected, setSelected] = useState([])
  const [notes, setNotes] = useState(null)
  const [showDeleteModal, setShowDeleteModal, toggleShowDeleteModal] = useBoolState()

  const title = `Manage payment rules`

  useDocument({title})

  useEffect(() => setSelected([]), [rules])

  const SelectableTable = selectableCollection(Table, {
    onSelect: setSelected,
    selectedIdentifiers: selected
  })

  return (
    <Fragment>
      <Confirmation
        isOpen={showDeleteModal}
        confirm={`Are you sure you want to delete ${pluralise(`payment rule`, selected.length, true)}?`}
        buttonClass='is-danger'
        buttonText='Delete'
        onClose={toggleShowDeleteModal}
        onConfirm={() => destroy({recordIds: selected, notes})}
        onCancel={() => setNotes(null)}>
        <InputField
          value={notes || ''}
          type='textarea'
          name='notes'
          label={`Reason for deleting? (optional)`}
          onChange={({notes}) => setNotes(notes)}
          rows={2}
        />
      </Confirmation>

      <Page
        title={title}
        subtitle={`Showing ${rules.length} of ${pluralise(`payment rule`, totalCount, true)}`}
        buttons={
          <Link to={`${pathname}/new`} className='button is-light'>
            New payment rule
          </Link>
        }>
        {rules.size != 0 ? (
          <form className='box is-radiusless is-shadowless has-background-filters'>
            <div className='field is-grouped'>
              <div className='control is-expanded'>
                <InputField
                  type='search'
                  name='q'
                  label='Search'
                  autoComplete='off'
                  value={filters.q}
                  onChange={changes => setFilters({...changes, page: 1})}
                />
              </div>
            </div>
          </form>
        ) : null}

        {rules.length != 0 ? (
          <Fragment>
            <div className='level'>
              {selected.length ? (
                <div className='level-left has-background-info box mb-0 is-shadowless'>
                  <div className='level-item has-text-white'>
                    {selected.length === rules.length ? 'All ' : ''}
                    {pluralise('payment rule', selected.length, true)} on this page {pluralise('is', selected.length)}{' '}
                    selected
                  </div>
                  <Dropdown id='rules-actions-menu' className='level-item'>
                    <button className='button is-outlined is-small'>
                      <span>Action</span>
                      <span className='icon is-small'>
                        <i className='fa fa-caret-down' aria-hidden='true'></i>
                      </span>
                    </button>
                    <a onClick={toggleShowDeleteModal} className='dropdown-item'>
                      Delete
                    </a>
                  </Dropdown>
                </div>
              ) : (
                <div className='level-left'></div>
              )}

              <div className='level-right'>
                <Pagination
                  page={filters.page}
                  limit={filters.limit}
                  total={totalCount}
                  onChange={setFilters}
                  className='level-item'
                />
              </div>
            </div>

            <SelectableTable
              data={rules}
              onClick={paymentRuleId => history.push(`${pathname}/${paymentRuleId}`)}
              onSort={setFilters}
              sortBy={filters.sortBy}
              sortDirection={filters.sortDirection}
            />

            {rules.length >= 10 && (
              <div className='level'>
                <div className='level-left'></div>
                <div className='level-right'>
                  <Pagination
                    page={filters.page}
                    limit={filters.limit}
                    total={totalCount}
                    onChange={setFilters}
                    className='level-item'
                  />
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <p>No payment rules</p>
        )}

        <Route exact path={`${match.path}/:paymentRuleId(\\d+)`} component={PaymentRule} />
        <Route path={`${match.path}/new`} component={NewPaymentRule} />
      </Page>
    </Fragment>
  )
}
