import React, {useState, Fragment} from 'react'
import {isEmpty} from 'lodash'
import {useParams, useHistory} from 'react-router-dom'
import useBoolState from 'admin/hooks/useBoolState'
import useEffect from 'admin/hooks/useEffect'
import usePaymentRule from 'admin/hooks/usePaymentRule'
import useDocument from 'admin/hooks/useDocument'
import useStateObject from 'admin/hooks/useStateObject'
import InputField from 'admin/components/ui/InputField'
import Modal from 'admin/components/ui/Modal'
import AccountField from 'admin/components/shared/AccountField'
import PaymentRuleCondition from 'admin/components/shared/PaymentRuleCondition'
import Confirmation from 'admin/components/Confirmation'

const DEFAULT_CONDITION = {
  fieldName: 'any_field',
  matchType: 'contains'
}

export default () => {
  const history = useHistory()
  const {organisationID, paymentRuleId} = useParams()
  const {paymentRule, errors, update, destroy} = usePaymentRule(organisationID, paymentRuleId)
  const [form, setForm] = useStateObject(isEmpty(paymentRule) ? {conditions: [DEFAULT_CONDITION]} : paymentRule)
  const [removedConditions, setRemovedConditions] = useState([])
  const [notes, setNotes] = useState(null)
  const [showDeleteModal, setShowDeleteModal, toggleShowDeleteModal] = useBoolState()

  useDocument({title: `Edit payment rule${paymentRule?.id ? ` #${paymentRule.id}` : ''}`})

  useEffect(() => setForm(paymentRule), [paymentRule], false)

  const handleUpdate = e => {
    e.preventDefault()
    let conditions = [...form.conditions]
    removedConditions.forEach(condition => conditions.push({...condition, _destroy: true}))
    update({...form, conditions}, history.goBack)
  }

  const handleDestroy = () => {
    destroy({recordIds: [paymentRuleId], notes}, history.goBack)
  }

  const handleConditionChange = (index, change) => {
    let conditions = [...form.conditions]
    conditions[index] = {...conditions[index], ...change}
    setForm({conditions})
  }

  const handleAddCondition = () => {
    let conditions = [...form.conditions]
    conditions.push(DEFAULT_CONDITION)
    setForm({conditions})
  }

  const handleRemoveCondition = index => {
    let conditions = [...form.conditions]
    let removed = conditions.splice(index, 1)
    setRemovedConditions([...removedConditions, ...removed])
    if (isEmpty(conditions)) conditions = [DEFAULT_CONDITION]
    setForm({conditions})
  }

  return (
    <Fragment>
      <Modal onClose={history.goBack} isOpen={true}>
        <section className='section has-background-light'>
          <div className='container'>
            <div className='columns  is-vcentered is-mobile'>
              <div className='is-5 column'>
                <h3 className='title is-marginless'>Add payment rule</h3>
              </div>
              <div className='is-7 column buttons is-right'>
                <button className='button is-danger is-outlined' onClick={toggleShowDeleteModal}>
                  Delete
                </button>
                <button className='button is-success' onClick={handleUpdate}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className='section'>
          <div className='container'>
            <p className='title is-5 mt-0'>When a payment's...</p>

            {form.conditions.map((condition, i) => (
              <PaymentRuleCondition
                key={i}
                index={i}
                isAlone={form.conditions.length === 1}
                {...condition}
                errors={errors.conditions && errors.conditions[i]}
                onChange={handleConditionChange}
                onRemove={handleRemoveCondition}
              />
            ))}

            <div className='buttons is-right'>
              <button className='button is-outlined is-dark' onClick={handleAddCondition}>
                Add another condition
              </button>
            </div>

            <p className='title is-5 mt-0'>match it to the account...</p>

            <AccountField
              placeholder='Account'
              showLabel={false}
              value={form.account?.displayName}
              organisationID={organisationID}
              onSelect={account => setForm({account, accountId: account?.id})}
              errors={errors.account}
            />

            <p className='title is-5'>and set the description to...</p>

            <InputField
              placeholder='Description'
              showLabel={false}
              name='description'
              value={form.description || ''}
              errors={errors.description}
              onChange={setForm}
            />
          </div>
        </section>
      </Modal>

      <Confirmation
        isOpen={showDeleteModal}
        confirm='Are you sure you want to delete this payment rule?'
        buttonClass='is-danger'
        buttonText='Delete'
        onClose={toggleShowDeleteModal}
        onConfirm={handleDestroy}
        onCancel={() => setNotes(null)}>
        <InputField
          value={notes || ''}
          type='textarea'
          name='notes'
          label='Reason for deleting? (optional)'
          onChange={({notes}) => setNotes(notes)}
          rows={2}
        />
      </Confirmation>
    </Fragment>
  )
}
