import React, {Fragment} from 'react'
import invert from 'invert-color'
import {formatNumber, formatDate} from 'admin/lib/format'
import sortable from 'admin/components/hoc/sortable'

export default ({isDraft, onClick, onSort, sortBy, sortDirection, data, SelectAll, SelectOne}) => {
  const SortableTH = sortable('th', {onSort, sortBy, sortDirection, camelcase: true})

  return (
    <table className='table is-striped is-hoverable is-fullwidth is-linked'>
      <colgroup>
        {isDraft ? (
          <Fragment>
            <col span='1' style={{width: '2%'}} />
            <col span='1' style={{width: '35%'}} />
            <col span='1' style={{width: '18%'}} />
            <col span='1' style={{width: '25%'}} />
            <col span='1' style={{width: '20%'}} />
          </Fragment>
        ) : (
          <Fragment>
            <col span='1' style={{width: '2%'}} />
            <col span='1' style={{width: '20%'}} />
            <col span='1' style={{width: '8%'}} />
            <col span='1' style={{width: '30%'}} />
            <col span='1' style={{width: '10%'}} />
            <col span='1' style={{width: '10%'}} />
            <col span='1' style={{width: '10%'}} />
            <col span='1' style={{width: '10%'}} />
          </Fragment>
        )}
      </colgroup>
      <thead>
        <tr>
          <th>
            <SelectAll />
          </th>
          <SortableTH name='name' />
          <SortableTH name='Customer number' column='customer_number' />
          <th>Groups</th>
          <SortableTH className={isDraft ? `has-text-right` : ''} name='Date created' column='created_at' />
          {isDraft ? null : (
            <Fragment>
              <SortableTH name='Last transaction date' column='last_transaction_date' />
              <SortableTH className='has-text-right' name='balance' />
              <SortableTH className='has-text-right' name='Overdue since' column='overdue_since' />
            </Fragment>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((account, i) => {
          const absoluteBalance = formatNumber(Math.abs(account.balance))
          const handleRowClick = e => {
            if (e.target.type !== 'checkbox') onClick(account.id)
          }
          return (
            <tr key={i} onClick={handleRowClick}>
              <td>
                <SelectOne identifier={account.id} />
              </td>
              <td>
                <b>{account.displayName}</b>
                <sub className='is-size-6'>{account.physicalAddress || account.postalAddress}</sub>
              </td>
              <td>{account.customerNumber}</td>
              <td>
                <div className='tags mt-1 mb-0'>
                  {account.groups &&
                    account.groups.map((group, i) => {
                      const style = group.colour
                        ? {backgroundColor: `#${group.colour}`, color: invert(`#${group.colour}`, true)}
                        : {}
                      return (
                        <span key={i} className='tag is-small' style={style}>
                          {group.name}
                        </span>
                      )
                    })}
                </div>
              </td>
              <td className={isDraft ? `has-text-right` : ''}>{formatDate(account.createdAt)}</td>
              {isDraft ? null : (
                <Fragment>
                  <td>{account.lastTransactionDate}</td>
                  <td className='has-text-right'>
                    <span className={account.balance < 0 ? 'has-text-danger' : ''}>
                      {account.balance > 0 ? `(${absoluteBalance})` : absoluteBalance}
                    </span>
                  </td>
                  <td>
                    <span className={account.overdueSince ? 'has-text-danger' : ''}>
                      {formatDate(account.overdueSince)}
                    </span>
                  </td>
                </Fragment>
              )}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
