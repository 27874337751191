import {useQuery} from 'admin/lib/apollo'
import {useQueryParams, StringParam, NumberParam, withDefault} from 'use-query-params'
import {GET_PAYMENT_RULES} from 'admin/gql/PaymentRules.gql'
import useEffect from 'admin/hooks/useEffect'
import useDestroyable from 'admin/hooks/useDestroyable'

export default organisationId => {
  const [filters, setFilters] = useQueryParams({
    q: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50),
    sortBy: withDefault(StringParam, ''),
    sortDirection: withDefault(StringParam, 'asc')
  })

  const variables = {organisationId, ...filters}

  const {data: {paymentRules = {}} = {}, fetchMore} = useQuery(GET_PAYMENT_RULES, {variables})
  useEffect(() => fetchMore({variables: filters}), [filters], false)

  const {destroy, errors: destroyError} = useDestroyable(organisationId, 'BankRule')

  return {
    filters,
    setFilters,
    paymentRules: paymentRules.collection || [],
    totalCount: paymentRules.metadata ? paymentRules.metadata.totalCount : 0,
    errors: destroyError,
    destroy
  }
}
