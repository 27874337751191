import {parseError, useMutation, useQuery} from 'admin/lib/apollo'
import {GET_PAYMENT_RULE, CREATE_PAYMENT_RULE, UPDATE_PAYMENT_RULE} from 'admin/gql/PaymentRule.gql'
import mutateWith from './mutateWith'
import clearCache from './clearCache'
import useDestroyable from 'admin/hooks/useDestroyable'

export default (organisationId, paymentRuleId) => {
  const {data: {paymentRule} = {}, error: getError} = useQuery(GET_PAYMENT_RULE, {
    variables: {organisationId, paymentRuleId},
    skip: !paymentRuleId,
    errorPolicy: 'all'
  })

  const [create, {error: createError}] = useMutation(CREATE_PAYMENT_RULE, {
    variables: {organisationId},
    errorPolicy: 'all',
    update: cache => clearCache({organisation: {id: organisationId}})(cache)
  })

  const [update, {error: updateError}] = useMutation(UPDATE_PAYMENT_RULE, {
    variables: {organisationId, paymentRuleId},
    errorPolicy: 'all'
  })

  const {destroy, errors: destroyError} = useDestroyable(organisationId, 'BankRule', paymentRuleId)

  return {
    paymentRule,
    errors: parseError(getError || createError || updateError) || destroyError,
    create: mutateWith(create, 'Payment rule created'),
    update: mutateWith(update, 'Payment rule updated'),
    destroy
  }
}
