import React from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import {QueryParamProvider} from 'use-query-params'

import createStore from 'admin/store'

import Organisation from 'admin/components/Organisation'

import Account from 'admin/components/Account'

import GroupsLayout from 'admin/components/layouts/GroupsLayout'
import AccountsLayout from 'admin/components/layouts/AccountsLayout'

import TextMessages from 'admin/components/TextMessages'

import User from 'admin/components/User'
import ListUsers from 'admin/components/Users'

import Invoice from 'admin/components/Invoice'
import NewInvoice from 'admin/components/NewInvoice'
import InvoiceActivity from 'admin/components/Invoice/Activity'
import ListApprovedInvoices from 'admin/components/InvoicesApproved'
import ListDraftInvoices from 'admin/components/InvoicesDraft'
import ListDeletedInvoices from 'admin/components/InvoicesDeleted'
import ListRepeatingInvoices from 'admin/components/RepeatingInvoices'
import ListPaymentRules from 'admin/components/PaymentRules'

import RepeatingInvoice from 'admin/components/RepeatingInvoice'
import NewRepeatingInvoice from './admin/components/NewRepeatingInvoice'
import Map from './admin/components/Map'

import client, {ApolloProvider} from 'admin/lib/apollo'

export default props => (
  <ApolloProvider client={client}>
    <Provider store={createStore(props)}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Route exact path='/orgs/:organisationID(\d+)/map' component={Map} />

          <Route
            path='/orgs/:organisationID(\d+)/invoices/deleted/:invoiceID(\d+)/activity'
            component={InvoiceActivity}
          />

          <Switch>
            <Route path='/orgs/:organisationID(\d+)/edit' component={Organisation} />

            <Route path='/orgs/:organisationID(\d+)/invoices/new' component={NewInvoice} />
            <Route path='/orgs/:organisationID(\d+)/repeating_invoices/new' component={NewRepeatingInvoice} />

            <Route
              path='/orgs/:organisationID(\d+)/repeating_invoices/:repeatingInvoiceID(\d+)'
              component={RepeatingInvoice}
            />

            <Redirect
              exact
              from='/orgs/:organisationID(\d+)/invoices'
              to='/orgs/:organisationID(\d+)/invoices/approved'
            />
            <Route path='/orgs/:organisationID(\d+)/invoices/draft' component={ListDraftInvoices} />
            <Route path='/orgs/:organisationID(\d+)/invoices/approved' component={ListApprovedInvoices} />
            <Route path='/orgs/:organisationID(\d+)/invoices/deleted' component={ListDeletedInvoices} />
            <Route path='/orgs/:organisationID(\d+)/repeating_invoices' component={ListRepeatingInvoices} />
            <Route path='/orgs/:organisationID(\d+)/payment_rules' component={ListPaymentRules} />
            <Route path='/orgs/:organisationID(\d+)/invoices/:invoiceID(\d+)' component={Invoice} />
            <Route path='/orgs/:organisationID(\d+)/accounts/:accountID(\d+)' component={Account} />
            <Route path='/orgs/:organisationID(\d+)/groups' component={GroupsLayout} />
            <Route path='/orgs/:organisationID(\d+)/text_messages' component={TextMessages} />
            <Route path='/orgs/:organisationID(\d+)/accounts' component={AccountsLayout} />
            <Route path='/orgs/:organisationID(\d+)/users/:userID(\d+)' component={User} />
            <Route path='/orgs/:organisationID(\d+)/users' component={ListUsers} />
          </Switch>
        </QueryParamProvider>
      </Router>
    </Provider>
  </ApolloProvider>
)
