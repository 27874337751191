import {parseError, useMutation, gql} from 'admin/lib/apollo'
import {isArray} from 'lodash'
import mutateWith from 'admin/hooks/mutateWith'

const MUTATION = gql`
  mutation DestroyRecords($organisationId: ID!, $recordType: String!, $recordIds: [String!]!) {
    destroy(organisationId: $organisationId, recordType: $recordType, recordIds: $recordIds) {
      ... on Group {
        id
      }
      ... on Invoice {
        id
      }
      ... on RepeatingInvoice {
        id
      }
      ... on Transaction {
        id
      }
      ... on Service {
        id
      }
      ... on PaymentRule {
        id
      }
    }
  }
`

export default (organisationId, recordType, recordId = []) => {
  const recordIds = isArray(recordId) ? recordId : [recordId]
  const [destroy, {error: destroyError}] = useMutation(MUTATION, {
    variables: {organisationId, recordType, recordIds},
    errorPolicy: 'all',
    update: (cache, {data: {destroy}, errors}) => {
      if (!errors) {
        destroy.forEach(record => {
          cache.evict({id: cache.identify(record)})
          cache.gc()
        })
      }
    }
  })

  return {
    errors: parseError(destroyError),
    destroy: mutateWith(destroy, 'Successfully deleted')
  }
}
