import {gql} from '@apollo/client'

export const GET_PAYMENT_RULE = gql`
  query GetPaymentRule($organisationId: ID!, $paymentRuleId: ID!) {
    paymentRule(organisationId: $organisationId, id: $paymentRuleId) {
      id
      description
      account {
        id
        displayName @client
        name
        contactFirstName
        contactLastName
      }
      conditions {
        id
        fieldName
        matchType
        text
      }
    }
  }
`

export const CREATE_PAYMENT_RULE = gql`
  mutation CreatePaymentRule($organisationId: ID!, $conditions: JSON, $description: String, $accountId: ID) {
    paymentRule(
      organisationId: $organisationId
      conditions: $conditions
      description: $description
      accountId: $accountId
    ) {
      id
      description
      account {
        displayName @client
        name
        contactFirstName
        contactLastName
      }
      conditions {
        id
        fieldName
        matchType
        text
      }
    }
  }
`

export const UPDATE_PAYMENT_RULE = gql`
  mutation UpdatePaymentRule($organisationId: ID!, $id: ID!, $conditions: JSON, $description: String, $accountId: ID) {
    paymentRule(
      organisationId: $organisationId
      id: $id
      conditions: $conditions
      description: $description
      accountId: $accountId
    ) {
      id
      description
      account {
        displayName @client
        name
        contactFirstName
        contactLastName
      }
      conditions {
        id
        fieldName
        matchType
        text
      }
    }
  }
`
