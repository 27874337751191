import {compact} from 'lodash'
import {ApolloClient, InMemoryCache} from '@apollo/client'

export * from '@apollo/client'

const cache = new InMemoryCache({
  possibleTypes: {
    StatementItem: ['Invoice', 'RepeatingInvoice', 'Transaction']
  },
  typePolicies: {
    Account: {
      fields: {
        displayName: {
          read(_, {readField}) {
            return readField('name') && readField('name').length
              ? readField('name')
              : compact([readField('contactFirstName'), readField('contactLastName')]).join(' ')
          }
        }
      }
    },
    Invoice: {
      fields: {
        description: {
          read(_, {readField}) {
            let lineItems = readField('lineItems')
            return lineItems && compact(lineItems.map(lineItem => lineItem.description)).join(', ')
          }
        }
      }
    },
    RepeatingInvoice: {
      fields: {
        description: {
          read(_, {readField}) {
            let lineItems = readField('lineItems')
            return lineItems && compact(lineItems.map(lineItem => lineItem.description)).join(', ')
          }
        }
      }
    }
  }
})

export default new ApolloClient({uri: '/graphql', cache, connectToDevTools: true})

const NESTED_ATTRS_REGEX = /^(.+)\[([0-9]+)\]\.(.+)$/
export const parseError = error => {
  if (!error) return {}
  return error.graphQLErrors.reduce((memo, {extensions}) => {
    if (extensions && extensions.attribute && extensions.messages) {
      if (NESTED_ATTRS_REGEX.test(extensions.attribute)) {
        let [_match, parent, index, attribute] = NESTED_ATTRS_REGEX.exec(extensions.attribute)
        memo[parent] ||= []
        memo[parent][index] ||= {}
        memo[parent][index][attribute] = extensions.messages
      } else {
        memo[extensions.attribute] = extensions.messages
      }
    }
    return memo
  }, {})
}
